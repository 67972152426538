import React, { FunctionComponent, useMemo, useState } from 'react'
import useStore from '___store'

import { Button, Input, Select } from 'components/CasusComponents'

export const Formatting: FunctionComponent<{ id: string }> = React.memo(({ id }) => {
  const { wizardLocationById, updateMarker } = useStore(`selectWizardLocationById[${id}]`, 'updateMarker')
  const { formatting: storeFormatting } = wizardLocationById

  const [formatType, setFormatType] = useState<string>(storeFormatting?.split(':')[0])
  const [formatOption, setFormatOption] = useState<string>(storeFormatting?.split(':')[1])
  const [formatDetails, setFormatDetails] = useState<string>(storeFormatting?.split(':')[2])

  const formatTypeOptions = useMemo(() => ({ number: 'Number' }), [])
  const numberFormatOptions = useMemo(() => ({ trunc: 'Truncate', round: 'Round', locale: 'Locale', currency: 'Currency' }), [])
  const localeFormatDetailOptions = useMemo(
    () => ({ 'de-CH': 'Swiss German', 'en-UK': 'English (United Kingdom)', 'en-US': 'English (United States)' }),
    []
  )
  const currencyFormatDetailOptions = useMemo(() => ({ EUR: 'Euro', CHF: 'Swiss franc', USD: 'United States dollar' }), [])

  const formatting = useMemo(
    () => formatType && formatOption && formatDetails && [formatType, formatOption, formatDetails].join(':'),
    [formatType, formatOption, formatDetails]
  )

  return (
    <div className="Marker-Configuration-Formatting">
      <span>Formatting</span>
      <Select value={formatType} onSelect={setFormatType} options={formatTypeOptions} />
      {formatType === 'number' ? <Select value={formatOption} onSelect={setFormatOption} options={numberFormatOptions} /> : null}
      {formatOption === 'trunc' ? (
        <Input
          type="number"
          value={formatDetails ?? 0}
          options={{ step: 1, min: 0 }}
          onInput={event => setFormatDetails((event.target as HTMLInputElement).value)}
        />
      ) : null}
      {formatOption === 'round' ? (
        <Input
          type="number"
          value={formatDetails ?? 0}
          options={{ min: 0 }}
          onInput={event => setFormatDetails((event.target as HTMLInputElement).value)}
        />
      ) : null}
      {formatOption === 'locale' ? <Select value={formatDetails} onSelect={setFormatDetails} options={localeFormatDetailOptions} /> : null}
      {formatOption === 'currency' ? <Select value={formatDetails} onSelect={setFormatDetails} options={currencyFormatDetailOptions} /> : null}
      <Button onClick={() => updateMarker({ id, formatting })} disabled={!(formatting && formatting !== storeFormatting)} primary>
        Apply formatting
      </Button>
    </div>
  )
})

Formatting.displayName = ''

export default Formatting

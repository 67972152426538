import React, { FunctionComponent, useMemo, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStore, {
  WizardQuestionOrderSelector,
  WizardActiveSplitSelector,
  ActivateSplitAction,
  UpdateWizardStateAction,
  WizardSplitsSelector,
  WizardIntegrationsSelector,
} from '___store'

import { DOCUMENT_STATUSES, DOCUMENT_FLOW_MODES, WIZARD_URL_PARAM_MATCH_ALL, DocumentStatus, DOCUMENT_DIRECTORY } from '___types'
import { useUser, useDocument } from '___hooks'
import { Check, Document, Download, Eye, Spinner, Signature as SignatureIcon } from 'assets/svgIconComponents'
import { Button, Link, Publishing, Signature } from 'components/CasusComponents'
import { Bamboo } from 'components/Integrations'
import useModal from 'Modal'
import {
  WizardLayoutLeftPaneDocumentPreviewProps,
  wizardLayoutLeftPaneDocumentPreviewClasses as classes,
  useWizardLayoutContext,
} from 'Layouts/WizardLayout'

type UseStoreHookResultType = {
  wizardQuestionOrder: WizardQuestionOrderSelector
  wizardIntegrations: WizardIntegrationsSelector
  wizardSplits: WizardSplitsSelector
  wizardActiveSplit: WizardActiveSplitSelector
  activateSplit: ActivateSplitAction
  updateWizardState: UpdateWizardStateAction
}

const draftStatuses = [DOCUMENT_STATUSES.LOCKED, DOCUMENT_STATUSES.DRAFT] as DocumentStatus[]
const editableStatuses = [DOCUMENT_STATUSES.DRAFT, DOCUMENT_STATUSES.FINAL, DOCUMENT_STATUSES.PENDING_APPROVAL] as DocumentStatus[]
const preparedStatuses = [DOCUMENT_STATUSES.LOCKED, DOCUMENT_STATUSES.FINAL] as DocumentStatus[]
const pendingStatuses = [DOCUMENT_STATUSES.PENDING_SIGNATURE, DOCUMENT_STATUSES.PENDING_APPROVAL] as DocumentStatus[]
const approvalStatuses = [DOCUMENT_STATUSES.LOCKED, DOCUMENT_STATUSES.PENDING_APPROVAL] as DocumentStatus[]

export const Preview: FunctionComponent<WizardLayoutLeftPaneDocumentPreviewProps> = React.memo(() => {
  const { t: translate } = useTranslation('translation')
  const { search } = useLocation()
  const { templateId, documentId } = useParams() as { templateId: string; documentId: string }
  const history = useHistory()
  const open = useModal()
  const { wizardQuestionOrder, wizardIntegrations, wizardSplits, wizardActiveSplit, activateSplit, updateWizardState } = useStore(
    'selectWizardQuestionOrder',
    'selectWizardIntegrations',
    'selectWizardSplits',
    'selectWizardActiveSplit',
    'activateSplit',
    'updateWizardState'
  ) as UseStoreHookResultType

  const publicFlow = useWizardLayoutContext()

  const { data: user } = useUser(publicFlow)
  const {
    data,
    approve,
    downloadRegular,
    submit,
    approving,
    creating,
    downloading,
    submitting,
    updating,
    requestRegularSignature,
    requestPDFSignature,
    requestingSignature,
    template,
  } = useDocument((documentId === 'new' ? undefined : documentId)!, publicFlow)

  const processingDocument = creating || updating
  const { status, progress, author, sharedWith, approvers, tags, signatureRequest, publicSettings: documentPublicSettings } = data || {}
  const publicSettings = Object.assign({}, template?.data?.publicSettings, documentPublicSettings)
  const isOwned = author?.id === user?.id
  const editable =
    editableStatuses.includes(status!) && (publicFlow || isOwned || sharedWith?.find(({ id, permissions }) => id === user?.id && permissions.write))
  const amApprover = approvalStatuses.includes(status!) && approvers?.includes(user?.id as string)

  const publicAnswered = progress?.public?.answered === progress?.public?.questions
  const privateAnswered = progress?.private?.answered === progress?.private?.questions
  const filledOut = publicAnswered && privateAnswered
  const unanswered =
    Math.max(0, (progress?.public?.questions ?? 0) - (progress?.public?.answered ?? 0)) +
    (publicFlow ? 0 : Math.max(0, (progress?.private?.questions ?? 0) - (progress?.private?.answered ?? 0)))
  const requiresApproval = draftStatuses.includes(status!) && filledOut && template?.data?.approvals?.length

  const message = useMemo(() => {
    const approvalRequiredMessage = translate('wizard.document-flow.document-preview.approval-required-message')
    const approverMessage = translate('wizard.document-flow.document-preview.approver-message')
    const approvalPendingMessage = translate('wizard.document-flow.document-preview.approval-pending-message')
    const approvedMessage = translate('wizard.document-flow.document-preview.approved-message')
    const incompleteMessage = translate('wizard.document-flow.document-preview.incomplete-message', {
      count: unanswered,
      object: translate(`general.question.${unanswered === 1 ? 'singular' : 'plural'}`),
      'modifier-verb': translate(`general.to-be.${unanswered === 1 ? 'singular' : 'plural'}.3rd`),
    })
    const readyMessage = translate('wizard.document-flow.document-preview.ready-message')
    if (status === DOCUMENT_STATUSES.PENDING_SIGNATURE) return 'This document has pending signature requests.'
    if (requiresApproval) return approvalRequiredMessage
    if (status === DOCUMENT_STATUSES.PENDING_APPROVAL) return amApprover ? approverMessage : approvalPendingMessage
    if (tags?.includes('approved')) return approvedMessage
    if (unanswered) return incompleteMessage
    return readyMessage
  }, [translate, unanswered, requiresApproval, status, amApprover, tags])

  const guide = useMemo(() => {
    if (publicSettings?.lastStepConfig?.message) return publicSettings.lastStepConfig.message
    const approvalRequiredGuide = translate('wizard.document-flow.document-preview.approval-required-guide')
    const approverGuide = translate('wizard.document-flow.document-preview.approver-guide')
    const approvalPendingGuide = translate('wizard.document-flow.document-preview.approval-pending-guide')
    const approvedGuide = translate('wizard.document-flow.document-preview.approved-guide')
    const incompleteGuide = translate('wizard.document-flow.document-preview.incomplete-guide')
    const readyGuide = translate('wizard.document-flow.document-preview.ready-guide')
    if (status === DOCUMENT_STATUSES.PENDING_SIGNATURE)
      return 'This document cannot be edited at the moment. In order to edit the document, the pending signature requests must be recinded.'
    if (requiresApproval) return approvalRequiredGuide
    if (status === DOCUMENT_STATUSES.PENDING_APPROVAL) return amApprover ? approverGuide : approvalPendingGuide
    if (tags?.includes('approved')) return approvedGuide
    if (unanswered) return incompleteGuide
    return readyGuide
  }, [publicSettings, translate, unanswered, requiresApproval, status, amApprover, tags])

  const params = useMemo(
    () =>
      Array.from(search.matchAll(WIZARD_URL_PARAM_MATCH_ALL)).reduce(
        (result, { groups: { key, value } = {} }) => (key ? Object.assign(result, { [key]: value }) : result),
        {}
      ) as Record<string, string>,
    [search]
  )

  const splits = useMemo(() => {
    const splits = Object.entries(wizardSplits || {})
    return splits.length ? (
      <div className={classes.actions.splits.wrapper}>
        {splits.map(([split, label]) => (
          <Button
            key={`WizardLayout-DocumentPreview-Split-${split}`}
            className={classes.actions.splits.entry}
            onClick={() => activateSplit(split)}
            disabled={split === wizardActiveSplit || undefined}
            tertiary={split === wizardActiveSplit || undefined}
          >
            <Eye active={split === wizardActiveSplit} />
            {label}
          </Button>
        ))}
      </div>
    ) : null
  }, [wizardSplits, activateSplit, wizardActiveSplit])

  const splitBeingSigned = useMemo(
    () => Boolean(signatureRequest?.find(request => (wizardActiveSplit ? request.splitId === wizardActiveSplit : request.splitId === 'general'))),
    [signatureRequest, wizardActiveSplit]
  )

  const hasBambooIntegration = useMemo(
    () => Object.values(wizardIntegrations || {}).some(integration => integration.id === 'bamboo-hr'),
    [wizardIntegrations]
  )

  useEffect(() => {
    if (Object.keys(wizardSplits || {}).length && !wizardActiveSplit) activateSplit(params.split || Object.keys(wizardSplits!)[0])
  }, [wizardSplits, wizardActiveSplit, activateSplit, params])

  useEffect(() => {
    if (publicFlow && status === DOCUMENT_STATUSES.DRAFT && !submitting) submit()
  }, [publicFlow, status, submitting, submit])

  return (
    <div className={classes.wrapper}>
      <span className={classes.message.primary}>{message}</span>
      <span className={classes.message.secondary}>{guide}</span>
      <div className={classes.actions.wrapper}>
        {!publicFlow || publicSettings?.previewAvailable ? splits : null}
        {draftStatuses.includes(status!) ? (
          <Button
            onClick={() => submit()}
            disabled={status === DOCUMENT_STATUSES.LOCKED || typeof submit !== 'function' || submitting}
            loading={submitting}
            primary
          >
            <Check />
            {requiresApproval
              ? translate(`wizard.document-flow.document-preview.${submitting ? 'requesting-approval' : 'request-approval'}`)
              : translate(`wizard.document-flow.document-preview.${submitting ? 'submitting' : 'submit'}`)}
          </Button>
        ) : null}
        {preparedStatuses.includes(status!) &&
        publicSettings.customLastStepAvailable &&
        publicSettings?.lastStepConfig?.ctaLabel &&
        publicSettings?.lastStepConfig?.ctaLink &&
        !submitting ? (
          <Link properties={{ href: publicSettings.lastStepConfig.ctaLink, text: publicSettings.lastStepConfig.ctaLabel }} />
        ) : null}
        {preparedStatuses.includes(status!) && !publicFlow ? <Signature id={documentId} splitId={wizardActiveSplit} publicFlow={publicFlow} /> : null}
        {publicFlow && publicSettings?.signatureAvailable ? (
          <Button
            onClick={() => {
              if (data?.type === 'pdf')
                return requestPDFSignature(
                  (publicSettings.signatureConfig?.signees || []).concat(
                    publicSettings.includeEmail ? { email: publicSettings.notifyEmail || '', firstName: '', lastName: '' } : []
                  ),
                  publicSettings.signatureConfig?.security,
                  publicSettings.signatureConfig?.message,
                  {
                    onSuccess: document => {
                      const relevantSignatureRequest = document.signatureRequest.find(request =>
                        wizardActiveSplit ? request.splitId === wizardActiveSplit : request.splitId === 'general'
                      )
                      if (relevantSignatureRequest) history.push(relevantSignatureRequest.signing_url)
                    },
                  }
                )
              if (data?.type === 'regular')
                return requestRegularSignature(
                  (publicSettings.signatureConfig?.signees || []).concat(
                    publicSettings.includeEmail ? { email: publicSettings.notifyEmail || '', firstName: '', lastName: '' } : []
                  ),
                  publicSettings.signatureConfig?.security,
                  publicSettings.signatureConfig?.message,
                  wizardActiveSplit,
                  {
                    onSuccess: document => {
                      const relevantSignatureRequest = document.signatureRequest.find(request =>
                        wizardActiveSplit ? request.splitId === wizardActiveSplit : request.splitId === 'general'
                      )
                      if (relevantSignatureRequest) history.push(relevantSignatureRequest.signing_url)
                    },
                  }
                )
            }}
            disabled={requestingSignature || splitBeingSigned}
            loading={requestingSignature}
          >
            <SignatureIcon />
            {translate(`wizard.document-flow.document-preview.${splitBeingSigned ? 'signature-pending' : 'request-signatures'}`)}
          </Button>
        ) : null}
        {preparedStatuses.includes(status!) && (publicFlow || user?.privacy.docxDownload) ? (
          <Button
            onClick={() => downloadRegular!('docx', wizardActiveSplit || '')}
            disabled={status === DOCUMENT_STATUSES.LOCKED || typeof downloadRegular !== 'function' || processingDocument || downloading.docx}
            loading={downloading.docx}
          >
            {typeof downloadRegular !== 'function' || downloading.docx ? <Spinner strokeWidth={5} /> : <Download />}
            {translate(
              `wizard.document-flow.document-preview.${
                processingDocument
                  ? 'processing'
                  : downloading.docx
                  ? 'awaiting-download'
                  : typeof downloadRegular === 'function'
                  ? 'download-docx'
                  : 'preparing-download'
              }`
            )}
          </Button>
        ) : null}
        {preparedStatuses.includes(status!) && (publicFlow || user?.privacy.pdfDownload) ? (
          <Button
            onClick={() => downloadRegular!('pdf', wizardActiveSplit || '')}
            disabled={status === DOCUMENT_STATUSES.LOCKED || typeof downloadRegular !== 'function' || processingDocument || downloading.pdf}
            loading={downloading.pdf}
          >
            {typeof downloadRegular !== 'function' || downloading.pdf ? <Spinner strokeWidth={5} /> : <Download />}
            {translate(
              `wizard.document-flow.document-preview.${
                processingDocument
                  ? 'processing'
                  : downloading.pdf
                  ? 'awaiting-download'
                  : typeof downloadRegular === 'function'
                  ? 'download-pdf'
                  : 'preparing-download'
              }`
            )}
          </Button>
        ) : null}
        {amApprover ? (
          <Button
            onClick={() => approve()}
            disabled={status === DOCUMENT_STATUSES.LOCKED || typeof approve !== 'function' || approving}
            loading={approving}
          >
            <Check />
            {translate('wizard.document-flow.document-preview.approve-document')}
          </Button>
        ) : null}
        {editable && !publicFlow ? (
          <Button
            onClick={() => {
              if (tags?.includes('approved')) {
                return open({
                  header: false,
                  content: (
                    <div className={classes.editWarning}>
                      <span>WARNING!!!</span>
                      Editing the document will invalidate any existing approvals, requiring the document to be re-submitted for approval.
                    </div>
                  ),
                  cancel: 'Go back!',
                  conclude: 'Continue...',
                  onConclude: () => {
                    updateWizardState({ mode: DOCUMENT_FLOW_MODES.EDIT, answering: wizardQuestionOrder && wizardQuestionOrder[0] })
                    history.push(`/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/${documentId}`)
                  },
                  type: 'warning',
                })
              }
              updateWizardState({ mode: DOCUMENT_FLOW_MODES.EDIT, answering: wizardQuestionOrder && wizardQuestionOrder[0] })
              history.push(`/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/${documentId}`)
            }}
            disabled={status === DOCUMENT_STATUSES.LOCKED}
          >
            <Document function="edit" />
            {translate('wizard.document-flow.document-preview.edit-document')}
          </Button>
        ) : null}
        {isOwned && !publicFlow && !pendingStatuses.includes(status!) ? <Publishing id={documentId} entity="document" /> : null}
        {preparedStatuses.includes(status!) && hasBambooIntegration ? (
          <Bamboo.Upload documentId={documentId} disabled={status === DOCUMENT_STATUSES.LOCKED} />
        ) : null}
      </div>
    </div>
  )
})

Preview.displayName = 'WizardLayout-LeftPane-Document-Preview'

export default Preview
